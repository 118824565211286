(function () {

    //###[ PACKAGE DEFINITIONS ]########################################################################################
    var packagePaths = {
        'dolleApp': 'app/dolleApp',
        'util': 'app/util',

        'response': 'require/libs/response',
        'slick': 'require/libs/slick',

        'plugin-header': 'plugins/header',
        'plugin-footer': 'plugins/footer',
        'plugin-form': 'plugins/form',
        'choose-country': 'plugins/choose-country',
        'slider-upsell-related': 'plugins/slider-upsell-related',
        'plugin-intro': 'plugins/intro',
        'plugin-biu-events': 'plugins/biu-events',
        'plugin-biu-configurator': 'plugins/biu-configurator',
        'plugin-interactjs': 'plugins/interact',
        'plugin-jqueryuitouchpunch': 'plugins/jquery.ui.touch-punch.min',
        'plugin-product-teaser': 'plugins/product-teaser',
        'plugin-model-overview': 'plugins/model-overview',
        'plugin-model-search': 'plugins/model-search',
        'plugin-quadruple-slider': 'plugins/quadruple-slider',
        'plugin-single-slider': 'plugins/single-slider',
        'GoogleMapsApi': 'https://maps.googleapis.com/maps/api/js?libraries=places&key=' + window.DOLLE_SETTINGS.googleMapsApiKey + '&callback=init_map',
        'plugin-retailer': 'plugins/retailer',
        'RetailerApp': 'app/retailerApp',
        'retailer-consent': 'plugins/retailer-consent'
    };

    require.config({
        baseUrl: window.DOLLE_SETTINGS.staticBase + 'js',
        paths: packagePaths,
        waitSeconds: 0,

        shim: {
            'response': {
                exports: 'Response'
            },
            'GoogleMapsApi': {
                exports: 'google.maps'
            }
        }
    });

    //###[ GLOBAL MODULES ]#############################################################################################

    define(
        'jquery',
        [],
        function () {
            return window.$;
        }
    );

    // ###[ GLOBAL DEFINES ]############################################################################################

    define(
        'RETAILER_PUBLIC_PATH',
        [],
        function () {
            return '/typo3conf/ext/dolle_retailer/Resources/Public/';
        }
    );

    //###[ SINGLETONS ]#################################################################################################

    var dolleAppInstance = null;
    define(
        'dolleApp::',
        ['dolleApp'],
        function (DolleAppClass) {
            if (!$.isSet(dolleAppInstance)) {
                dolleAppInstance = new DolleAppClass();
            }
            return dolleAppInstance;
        }
    );

    //###[ PREPARATION ]################################################################################################

    /**
     * Deactivate console logging in production
     **/
    window.DOLLE_SETTINGS.debug = false;
    if (!window.DOLLE_SETTINGS.debug && 'undefined' !== typeof $) {
        $.log().disable();
    }

    require(['dolleApp::'], function () {
    });

    // TODO find a better method than require() here
    if (document.getElementById('biu-configurator-inner') !== null) {
        require(['plugin-interactjs'], function (i) {
            interact = i;
            return interact;
        });
    }

    if (document.getElementsByClassName('tx-dolle-retailer').length > 0) {
        require(['RetailerApp'], function (AppClass) {
            var app = new AppClass();
        });
    }

    // old jquery ui of solr needs deprecated $.curCSS method, do not remove
    if ('undefined' !== typeof $) {
        $.curCSS = function (element, attrib, val) {
            $(element).css(attrib, val);
        };
    }
    /**
     * load dynamic modules from markup
     **/
    require(_.unique(window.MODULE_REQUIREMENTS), function () {
    });

})();
